<template>
  <div>
    <p>Extra teaching sessions can be purchased by clicking on the "<i>Purchase Extra Sessions</i>" button below.</p>
    <b-button v-b-modal.confirm-purchase variant="primary" v-b-tooltip.hover title="Purchase Extra Teaching Sessions"
      >Purchase Extra Sessions</b-button
    >
    <div class="AskisMember"><b-button 
            :to="{ name: 'index' }"
            exact-active-class="active"
            variant="primary"
            class="logoudtBtn"
            v-b-tooltip.hover
            >Exit</b-button>
        </div>       

    <b-modal
      id="confirm-purchase"
      title="Purchase Extra Sessions"
      :ok-disabled="!isValid"
      :busy="processing"
      @ok="processPayment" @hidden="cleanupRecurly" @shown="initRecurly"
      ok-title="Process Payment"
    >
      <b-overlay :show="processing" rounded="sm">
        <b-alert
          :show="!!error"
          fade
          variant="warning"
          dismissible
          @dismissed="error = null"
          >{{ error }}</b-alert
        >
        <b-container fluid>
          <b-row class="my-1">
            <b-col sm="8" class="pl-0">
              <label for="number_lessons"
                >How many teaching sessions do you want:</label
              >
            </b-col>
            <b-col sm="4">
              <b-form-spinbutton
                id="number_lessons"
                type="number"
                v-model="numberOfSessions"
                min="1"
                max="20"
                step="1"
              />
            </b-col>
          </b-row>
        </b-container>
        <p>
          Confirm purchase of {{ numberOfSessions }} session{{
            numberOfSessions > 1 ? "s" : ""
          }}
          for {{ currenyformat(totalPrice)
          }}<template v-if="numberOfSessions > 1">
            at {{ currenyformat(pricePerSession) }} each</template
          >.
        </p>
        <b-form-group
          label="Select Payment Method:"
          v-slot="{ ariaDescribedby }"
          v-if="paymentMethods.length"
        >
          <template v-for="paymentMethod of paymentMethods">
            <b-form-radio
              v-model="selectedPaymentMethod"
              :key="paymentMethod.id"
              :aria-describedby="ariaDescribedby"
              name="selectedPaymentMethod"
              :value="paymentMethod.id"
            >
              <component
                :is="brandIcons[paymentMethod.type]"
                v-if="brandIcons[paymentMethod.type]"
                style="height: 1em; width: 2em"
              />
              <template v-else>{{ paymentMethod.type }}</template>
              {{ paymentMethod.maskedNumber }}
              <small v-if="paymentMethod.expiry">
                Expiry: {{ paymentMethod.expiry }}
              </small>
            </b-form-radio>
          </template>
          <b-form-radio
            v-model="selectedPaymentMethod"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            value="other"
            >Other Credit Card</b-form-radio
          >
        </b-form-group>
        <template v-if="selectedPaymentMethod === 'other'"
          ><!-- recurly element -->
          <div ref="cardElement"></div>
        </template>
        <div v-if="cardAuthActive" ref="authContainer"></div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script setup lang="ts">
/// <reference path="../../.nuxt/types/nitro-routes.d.ts" />
import { PurchasePost } from "@/server/api/purchase/[subscriberId]/extraSessions.post";
import { useFetch } from "~~/utils/auth";
import type { InternalApi } from "nitropack";
import brandIcons from "@iris/assets/cardLogos";
import externalScript from "@iris/externalScript";
import type {
  Address,
  CardElement,
  ElementsInstance,
  Recurly,
  TokenPayload,
} from "@recurly/recurly-js";
import { BvModalEvent } from "bootstrap-vue";
import axios from "axios";
import { AxiosError } from "axios";

export interface RecurlyFieldChangeResult {
  empty: boolean;
  focus: boolean;
  valid: boolean;
}

export interface RecurlyChangeResult extends RecurlyFieldChangeResult {
  firstSix: string;
  lastFour: string;
  brand: string;
  number: RecurlyFieldChangeResult;
  expiry: RecurlyFieldChangeResult;
  cvv: RecurlyFieldChangeResult;
}

interface RecurlyTransactionError {
  /**
   * Object type
   */
  object: "transaction_error";
  /**
   * Transaction ID
   */
  transactionId: string;
  /**
   * Category
   */
  category: string;
  /**
   * Code
   */
  code: string;
  /**
   * Customer message
   */
  message: string;
  /**
   * Merchant message
   */
  merchantAdvice: string;
  /**
   * Returned when 3-D Secure authentication is required for a transaction. Pass this value to Recurly.js so it can continue the challenge flow.
   */
  threeDSecureActionTokenId: string;
}

const instance = getCurrentInstance().proxy;
const props = defineProps<{
  subscriberId: number;
  sessionPricePoints: InternalApi["/api/subscription/:subscriberId"]["get"]["extraSessionCost"];
  paymentMethods: InternalApi["/api/subscription/:subscriberId"]["get"]["paymentMethods"];
  tokenInfo: Address;
}>();

const emits = defineEmits<{
  (event: "sessionsPurchased"): void;
}>();

const selectedPaymentMethod = ref<null | string | "other">();

const cardElement = ref<HTMLElement | null>();

const numberOfSessions = ref(1);

const recurlyData = ref<RecurlyChangeResult>();

const processing = ref(false);

const error = ref();

const currenyformat = (v: number) =>
  new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(
    v
  );

const pricePerSession = computed<number>(() => {
  let maxPricepoint = props.sessionPricePoints[0];
  for (const pricepoint of props.sessionPricePoints) {
    if (
      numberOfSessions.value >= pricepoint.numberOfSessions &&
      pricepoint.numberOfSessions > maxPricepoint.numberOfSessions
    ) {
      maxPricepoint = pricepoint;
    }
  }
  return maxPricepoint.cost / maxPricepoint.numberOfSessions;
});

const totalPrice = computed(() => {
  return pricePerSession.value * numberOfSessions.value;
});

const config = useRuntimeConfig();

let element: CardElement | undefined;
let elementsInstance: ElementsInstance | undefined;

    const cleanupRecurly = () => {
        destroyElements()
    }
    const initRecurly = () => {
        if (cardElement.value) {
            // init recurly
            createElements()
        }
    }

    const destroyElements = () => {
        if (element) {
                element.remove()
                element.off()
                element = undefined
            }
    }

    watchEffect(() => {
        if (cardElement.value) {
            // init recurly
            createElements()
        } else {
            // destroy recurly
            destroyElements()
        }
    })

/** is the data valid */
const isValid = computed(() => {
  if (numberOfSessions.value < 0 || numberOfSessions.value > 20) {
    return false;
  }
  if (selectedPaymentMethod.value && selectedPaymentMethod.value !== "other") {
    return true;
  }
  if (selectedPaymentMethod.value === "other" && recurlyData.value?.valid) {
    return true;
  }
  return false;
});

    const createElements = () => {
        // const elements = recurly.Elements()
        // elementsInstance = elements
        // let elements = instance.$stripe!.elements({ locale: 'en' })
        destroyElements()
        element = elementsInstance.CardElement({
        style: {
            lineHeight: '24px',
            placeholder: {
            color: '#495057'
            }
        }
        // style: {
        //   fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        //   lineHeight: '150%'

    // }
  });
  // debugger
  element.attach(cardElement.value);
  element.on("change", (state: RecurlyChangeResult) => {
    // TODO reset local state if token as already been done.
    // console.log('recurly change event fired!')
    // console.log(state)
    recurlyData.value = state;
  });
  // element.on('submit', ({ state } : { state: RecurlyChangeResult }) => {
  //   console.log('SUBMIT', state)
  // })
};

const getRecurlyToken = () => {
  return new Promise<TokenPayload>((resolve, reject) => {
    // overloads as want same return type as input type
    recurly.token(elementsInstance!, props.tokenInfo, (err, arg) => {
      return err ? reject(err) : resolve(arg);
    });
  });
};

onMounted(() => {
  return externalScript<Recurly>(
    "https://js.recurly.com/v4/recurly.js",
    "recurly",
    false
  ).then(() => {
    // console.log('loaded recurly')
    // console.log(result)
    recurly.configure({
      publicKey: config.public.recurlyPublicKey,
    });
    elementsInstance = recurly.Elements();
    // elementsInstance = elements

    // createElements()
    //   const elements = recurly.Elements()
    //   const card = elements.CardElement({
    //     style: {
    //       // inputType: 'mobileSelect',
    //       fontColor: '#010101'
    //     }
    //   })
    //   card.attach(instance.refs['card-element'] as HTMLElement)
  });
});

watchEffect(() => {
  if (!selectedPaymentMethod.value) {
    if (!props.paymentMethods || props.paymentMethods.length === 0) {
      selectedPaymentMethod.value = "other";
    } else {
      selectedPaymentMethod.value = props.paymentMethods?.find(
        (p) => p.primary
      )?.id;
    }
  }
});

/** axios response is a 3d secure request */
const isThreeDSecureError = (
  e: any
): e is AxiosError<{ data: RecurlyTransactionError }> &
  Required<Pick<AxiosError<{ data: RecurlyTransactionError }>, "response">> => {
  return (
    axios.isAxiosError(e) &&
    e.response &&
    e.response.data &&
    e.response.data.data &&
    e.response.status === 422 &&
    e.response.data.data.code === "three_d_secure_action_required" &&
    e.response.data.data.threeDSecureActionTokenId
  );
};

const cardAuthActive = ref(false);
const authContainer = ref<HTMLElement>();

const processPayment = async (evt: BvModalEvent) => {
  evt.preventDefault();
  processing.value = true;
  error.value = null;
  try {
    let tokenId: string | undefined;
    let billingInfoId: string | undefined;
    if (selectedPaymentMethod.value === "other") {
      tokenId = (await getRecurlyToken()).id;
    } else if (selectedPaymentMethod.value) {
      billingInfoId = selectedPaymentMethod.value;
    } else {
      throw new Error("need to choose a payment method");
    }
    const processPaymentWithOptionalToken = (
      threeDSecureActionResultTokenId?: string
    ) => {
      return instance.$axios
        .request<
          InternalApi["/api/purchase/:subscriberId/extraSessions"]["post"]
        >({
          method: "POST",
          url: `/api/purchase/${props.subscriberId}/extraSessions`,
          data: {
            costPerSession: pricePerSession.value,
            numberOfSessions: numberOfSessions.value,
            billingInfoId,
            tokenId,
            threeDSecureActionResultTokenId,
          } as PurchasePost,
        })
        .then((result) => {
          return result.data;
        });
    };
    const result = await processPaymentWithOptionalToken().catch((error) => {
      if (isThreeDSecureError(error)) {
        cardAuthActive.value = true;
        // const token = error.response.data.threeDSecureActionTokenId
        // debugger
        // data.error = error.response.data
        // 3d secure code
        return instance
          .$nextTick()
          .then(() => {
            return new Promise<{
              id: string;
              type: string;
            }>((resolve, reject) => {
              const risk = recurly.Risk();
              const threeDSecure = risk.ThreeDSecure({
                actionTokenId:
                  error.response.data.data.threeDSecureActionTokenId,
              });
              threeDSecure.attach(authContainer.value!);
              threeDSecure.on("error", reject);
              threeDSecure.on("token", resolve);
            });
          })
          .then((result) => {
            // console.log(result)
            cardAuthActive.value = false;
            return processPaymentWithOptionalToken(result.id);
          })
          .catch((e) => {
            cardAuthActive.value = false;
            throw e;
          });
      } else {
        throw error;
      }
    });
    // console.log(result)
    emits("sessionsPurchased");
    instance.$bvModal.hide("confirm-purchase");
  } catch (e) {
    if (axios.isAxiosError(e)) {
      error.value = e.response.data.message;
    } else {
      error.value = e;
    }
  }
  processing.value = false;
};
</script>

<style>
@import url("https://js.recurly.com/v4/recurly.css");
</style>

<style scoped>
.logoudtBtn {
  width: 160px;
 }
</style>
